@import "../../scss/_variables.scss";

.main-style-light {
  display: flex;
  flex: 1 1;
  min-width: 0;
  min-height: 100vh;
  flex-direction: column;
  transition: margin 0.3s;
  background-color: $background-color-lightmode;
}

.main-style-dark {
  display: flex;
  flex: 1 1;
  min-width: 0;
  min-height: 100vh;
  flex-direction: column;
  transition: margin 0.3s;
  color: #e1e1e1;
  /* Change this color for background*/
  background-color: $background-color-darkmode;
  --color: #e1e1e1;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}

.back-to-login-btn {
  height: 45px !important;
  padding: 6px 12px !important;
  line-height: 1.42857143 !important;
  letter-spacing: .1rem !important; 
  color: white !important;
  font-weight: bold !important;
  background: white;
  border-radius: 50px !important;
background-color: #243D90 !important;
  /* border: 2px solid rgba(183, 183, 183, 1); */
  border: transparent !important;
  box-shadow: 2px 12px 39px -6px rgba(36, 61, 144, 0.70) !important;
  -webkit-box-shadow: 2px 12px 39px -6px rgba(36, 61, 144, 0.70) !important;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
}